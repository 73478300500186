import { MetaSwiper, SafariSwiper } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

AOS.init({
  startEvent: "load",
  disableMutationObserver: false,
  duration: 600,
  once: true,
});
AOS.refresh(true);

const vh = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

vh();

window.onresize = () => vh();

const header = document.getElementsByClassName(`header`)[0];

// Hide and show Header when scroll up
const scrollContainer = () => {
  return document.documentElement || document.body;
};

function toggleHeader() {
  if (scrollContainer().scrollTop > header.clientHeight) {
    header.classList.add("scrolled");
  } else if (scrollContainer().scrollTop == 0) {
    header.classList.remove("scrolled");
  }
}

toggleHeader();

let scrollPosition = window.pageYOffset;

document.addEventListener("scroll", () => {
  toggleHeader();

  scrollPosition = window.pageYOffset;
});

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".header").toggleClass("active");
  $(".mobile-header").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");
  // LockScroll when burger open and enable when closed and enable scroll on menu
  if (scrollLock.getScrollState()) {
    scrollLock.disablePageScroll(
      document.querySelector(".mobile-navbar__wrap")
    );
    scrollLock.addScrollableSelector(
      ".mobile-header .simplebar-content-wrapper"
    );
  } else {
    scrollLock.clearQueueScrollLocks();
    scrollLock.enablePageScroll();
  }
}
//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".mobile-header").removeClass("active");
    $(".menu-toggle").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

// Rellax
const rellax = new Rellax(".parallax", {
  speed: 6,
});

if (rellax.elems) {
  let rellaxFlag = true;

  if (window.innerWidth < 1025 || window.innerHeight < 800) {
    rellax.destroy();
    rellaxFlag = false;
  }

  window.onresize = () => {
    vh();

    if ((rellaxFlag && window.innerWidth < 1025) || window.innerHeight < 800) {
      rellax.destroy();
      rellaxFlag = false;
    }
  };
}

// Tesla style menu
const navbar = header.querySelector(`.navbar-list`);
if (navbar) {
  const navbarElements = navbar.querySelectorAll(`.navbar-list__link`);
  const spanElement = navbar.querySelector(`span`);
  const activeMenuElement = navbar.querySelector(`.navbar-list__link.active`);

  function backgroundMenuPositionFunc(targetElement, flagMouseEnter) {
    const navbarPosition = navbar.getBoundingClientRect();
    const elementPosition = targetElement.getBoundingClientRect();

    let spanPositionLeftStart = elementPosition.left - navbarPosition.left;
    let spanWidthStart = elementPosition.width;

    if (flagMouseEnter) {
      spanElement.style.setProperty(
        "--span-transition",
        `0.5s cubic-bezier(0.75, 0, 0, 1)`
      );
    } else {
      spanElement.style.setProperty(
        "--span-transition",
        `opacity 0.5s ease, visibility 0s 0s`
      );
    }
    spanElement.style.setProperty("--width-span", `${spanWidthStart}px`);
    spanElement.style.setProperty(
      "--left-position-span",
      `${spanPositionLeftStart}px`
    );
  }

  if (activeMenuElement) {
    backgroundMenuPositionFunc(activeMenuElement, true);
    spanElement.classList.add("active");

    navbarElements.forEach((elem) => {
      elem.addEventListener("mouseenter", function (e) {
        backgroundMenuPositionFunc(e.target, true);
      });

      navbar.addEventListener("mouseleave", function (e) {
        backgroundMenuPositionFunc(activeMenuElement, true);
      });
    });
  } else {
    let flagMouseEnter = false;

    navbarElements.forEach((elem) => {
      elem.addEventListener("mouseenter", function (e) {
        backgroundMenuPositionFunc(e.target, flagMouseEnter);
        spanElement.classList.add("active");

        flagMouseEnter = true;
      });
    });

    navbar.addEventListener("mouseleave", function (e) {
      spanElement.classList.remove("active");
      flagMouseEnter = false;
      spanElement.style.setProperty(
        "--span-transition",
        `opacity 0.5s ease, visibility 0s 0.5s`
      );
    });
  }
}

// Hovering buttons
const followOnHover = (hovered, following) => {
  const onMouseMove = (event) => {
    let x = event.offsetX;
    let y = event.offsetY;

    if (event.target.classList.contains("btn")) {
      following.style.transform = `translate(calc(${x}px - 50%), calc(${y}px - 50%)) scale(0.5)`;
      following.style.borderWidth = "4px";
    }
  };

  const removeMouseMove = () => {
    following.style.transform = `translate(0, 0) scale(1)`;
    following.style.borderWidth = "";
  };

  hovered.addEventListener("mousemove", onMouseMove);

  hovered.addEventListener("mouseleave", removeMouseMove);

  if (window.innerWidth < 1025) {
    hovered.removeEventListener("mousemove", onMouseMove);
    hovered.removeEventListener("mouseleave", removeMouseMove);
  }
};

window.hoverButton = followOnHover;

const buttons = document.querySelectorAll(".btn");
if (buttons.length) {
  buttons.forEach((btn) => {
    let circle = btn.querySelector(".circle");

    followOnHover(btn, circle);
  });
}

// Choose File
const fileBtn = document.getElementById("cv_file");
if (fileBtn) {
  const formPreview = document.querySelector(".file__placeholder");

  fileBtn.addEventListener("change", () => {
    uploadFile(fileBtn.files[0]);
  });

  function uploadFile(file) {
    const fileRead = new FileReader();
    formPreview.innerHTML = file.name;
  }
}

// Tables in content element
const tables = document.querySelectorAll("table");
if (tables.length) {
  tables.forEach((item) => {
    item.removeAttribute("align");

    const wrapper = document.createElement("div");
    wrapper.classList.add("table-box");

    item.parentElement.insertBefore(wrapper, item);
    wrapper.appendChild(item);

    new SimpleBar(wrapper, { autoHide: false });
  });
}

// Cards hover
VanillaTilt.init(document.querySelectorAll("[data-tilt]"), {
  scale: 1.1,
  glare: true,
});

// Hide/Show
const accordion = document.querySelectorAll(".acc-content");
if (accordion.length) {
  const showBtn = document.querySelectorAll(".acc-trigger");

  let flag = true;

  accordion.forEach((acc, i) => {
    if (acc.classList.contains("charity-info__desc")) {
      const styleHeight = window.getComputedStyle(acc).height.match(/\d+/)[0];

      if (acc.scrollHeight <= styleHeight) showBtn[i].style.display = "none";
    }

    showBtn[i].addEventListener("click", () => {
      // console.log(acc.scrollHeight);

      showBtn[i].classList.toggle("open");

      const title = showBtn[i].querySelector(".title");

      let styleName = "maxHeight";

      if (acc.classList.contains("filters-list")) {
        styleName = "height";
      }

      if (acc.classList.contains("charity-info__desc")) {
        acc.classList.toggle("open");
        flag = false;
      }

      if (flag) {
        if (showBtn[i].classList.contains("open")) {
          acc.style[styleName] = acc.scrollHeight + "px";
          acc.classList.add("open");
        } else {
          acc.style[styleName] = "";
          acc.classList.remove("open");
        }
      }

      if (showBtn[i].classList.contains("open")) {
        if (title) title.textContent = "Read Less";
      } else {
        if (title) title.textContent = "Read More";
      }
    });
  });
}

// Range
const doubleRange = document.getElementsByClassName("double-range-tooltips")[0];
if (doubleRange) {
  const slider = doubleRange.querySelector("#double-range-tooltips");
  const max = +slider.dataset.max;
  const min = +slider.dataset.min;
  const unit = slider.dataset?.unit || "£";
  const step = +slider.dataset.step;
  const inputsHidden = doubleRange.querySelectorAll(
    ".double-range-hidden-input"
  );
  const startValueMin = +inputsHidden[0].value;
  const startValueMax = +inputsHidden[1].value;

  window.rangeSlider = slider;

  // how many percentages limit from borders ???? is 8%
  const borderLimit = 8;

  // each step is go backward for this amount of % ???? is 5%
  const borderDiff = 40 / borderLimit;

  noUiSlider.create(slider, {
    start: [startValueMin, startValueMax],
    connect: true,
    tooltips: true,
    margin: 10,
    step: step,
    range: {
      min: min,
      max: max,
    },
  });

  const tooltipsArr = slider.querySelectorAll(".noUi-tooltip");
  const circlesArr = slider.querySelectorAll(".noUi-origin");

  function returnTransform(element) {
    return element
      .replace(/[^0-9][^\d.]/g, "")
      .replace(")", "")
      .split(" ")
      .map((str) => {
        return Number(str);
      });
  }

  function needToMerge() {
    let tooltipOnePosition = tooltipsArr[0].getBoundingClientRect();
    let tooltipTwoPosition = tooltipsArr[1].getBoundingClientRect();

    if (
      tooltipsArr[0].classList.contains("hidden") ||
      tooltipsArr[1].classList.contains("hidden")
    ) {
      return true;
    }

    return (
      tooltipOnePosition.left +
        tooltipOnePosition.width -
        tooltipTwoPosition.left >
      0
    );
  }

  function resetTooltips(values) {
    mergeDiff = null;
    tooltipsArr.forEach((elem, index) => {
      elem.textContent =
        unit + Math.round(values[index]).toLocaleString("en-GB");
      elem.classList.remove("hidden");
    });
  }

  let trueTooltip = false;
  let mergeDiff = null;

  slider.noUiSlider.on("update", function (values, handle) {
    // translate of noUISlider -> 0% is start, 100% is end
    let translate = returnTransform(circlesArr[handle].style.transform)[0];

    // min value of double range slider
    let valueMin = returnTransform(circlesArr[0].style.transform)[0];

    // max value of double range slider
    let valueMax = returnTransform(circlesArr[1].style.transform)[0];

    // difference between min and max value of double range slider
    let difference = valueMax - valueMin;

    inputsHidden[handle].value = Math.round(values[handle]);

    // if tooltips are close to each other
    if (needToMerge()) {
      if (
        !tooltipsArr[+!handle].classList.contains("hidden") &&
        !tooltipsArr[handle].classList.contains("hidden")
      ) {
        trueTooltip = handle;
        mergeDiff = difference;
        tooltipsArr[+!handle].classList.add("hidden");
      }

      if (trueTooltip) {
        // limit left merged tooltip from overflowing
        // borderLimit * 3 === need for 3 times faster limitation because of merged tooltip
        if (translate <= -100 + borderLimit * 3) {
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 -
            difference +
            (Math.abs(translate + 100 - borderLimit * 3) * borderDiff) / 2.5
          }%,100%)`;
        } else {
          // position of tooltip in the middle of range
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 - difference
          }%,100%)`;
        }
      } else {
        // if left tooltip is grabbed
        if (translate >= -borderLimit * 4) {
          // limit right merged tooltip from overflowing
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 +
            difference -
            (Math.abs(translate + borderLimit * 3) * borderDiff) / 2.5
          }%,100%)`;
        } else {
          tooltipsArr[trueTooltip].style.transform = `translate(${
            -50 + difference
          }%,100%)`;
        }
      }

      tooltipsArr[trueTooltip].textContent = `${
        unit + Math.round(values[0]).toLocaleString("en-GB")
      } - ${unit + Math.round(values[1]).toLocaleString("en-GB")}`;

      if (mergeDiff - difference < 0) {
        mergeDiff = null;
        resetTooltips(values);
      }
    } else {
      // limit left solo tooltip from overflowing
      if (translate <= -100 + borderLimit) {
        tooltipsArr[0].style.transform = `translate(${
          -50 + Math.abs(translate + 100 - borderLimit) * borderDiff
        }%,100%)`;
      } else if (translate >= -borderLimit) {
        // limit right solo tooltip from overflowing
        tooltipsArr[1].style.transform = `translate(${
          -50 - Math.abs(translate + borderLimit) * borderDiff
        }%,100%)`;
      } else {
        // position of tooltip in the middle of range
        tooltipsArr[handle].style.transform = "translate(-50%,100%)";
      }

      tooltipsArr[handle].textContent = `${
        unit + Math.round(values[handle]).toLocaleString("en-GB")
      }`;
    }
  });
}

// Select
const selectItems = document.querySelectorAll(".select-item");
if (selectItems.length) {
  selectItems.forEach((item) => {
    new Choices(item, {
      searchEnabled: false,
      itemSelectText: "",
    });
  });
}

// Format contact number
const contactsLinks = document.querySelectorAll(".contacts__link");
if (contactsLinks.length) {
  contactsLinks.forEach((link) => {
    if (link.href.startsWith("tel:")) {
      const phoneNum = link.innerText.replace(/\s/g, "");
      const span = link.querySelector("span") || link;

      if (phoneNum.split("").length <= 10) {
        // prettier-ignore
        span.textContent = `${phoneNum.substring(0, 3)} ${phoneNum.substring(3, 7)} ${phoneNum.substring(7)}`;
      } else {
        // prettier-ignore
        span.textContent = `${phoneNum.substring(0, 5)} ${phoneNum.substring(5, 8)} ${phoneNum.substring(8)}`;
      }
    }
  });
}

// Live roles forms
const filtersSection = document.querySelector(".hero-roles");
if (filtersSection) {
  // Forms
  const mainForm = filtersSection.querySelector(".main-filter");
  const advancedForm = filtersSection.querySelector(".advanced-filters");

  // Reset buttons
  const clearMain = mainForm.querySelector(".clear-btn");
  const clearAdvanced = advancedForm.querySelector(".clear-btn");

  // Search buttons
  const searchButtons = filtersSection.querySelectorAll(".search-btn");
  searchButtons.forEach((btn) => {
    btn.addEventListener("click", () => {
      window.scrollTo(
        0,
        document.getElementById("roles-section").offsetTop -
          header.clientHeight * 2
      );
    });
  });

  // Array of advanced filters
  window.advancedChoices = [];

  // Remove placeholder in multiple select
  function removePlaceholder(select, choice) {
    const remove = () => {
      const selected = choice.passedElement.element.children;
      if (selected.length) {
        choice.input.element.style.display = "none";
      } else {
        choice.input.element.style.display = "";
      }
    };

    remove();

    select.addEventListener("change", () => remove());
  }

  // Reset function
  function resetChoices(choice) {
    choice.input.element.style.display = "";
    choice.removeActiveItems();
  }

  /* --- Main select ---- */
  const mainSelect = mainForm.querySelector(".multiple-select");
  const mainFilters = mainForm.querySelectorAll("input");

  const mainChoice = new Choices("#sectors", {
    searchEnabled: false,
    itemSelectText: "",
    placeholderValue: "Sector",
    removeItemButton: true,
    shouldSort: false,
  });

  removePlaceholder(mainSelect, mainChoice);

  /* --- Advanced selects ---- */
  const advancedSelects = advancedForm.querySelectorAll(".multiple-select");

  advancedSelects.forEach((select) => {
    const choice = new Choices(select, {
      searchEnabled: false,
      itemSelectText: "",
      placeholderValue: "Sector",
      removeItemButton: true,
      shouldSort: false,
    });

    // Show clear button
    if (select.value) clearAdvanced.style.display = "block";

    select.addEventListener("change", () => {
      clearAdvanced.style.display = "block";

      if (
        advancedSelects[0].selectedIndex === -1 &&
        advancedSelects[1].selectedIndex === -1 &&
        advancedSelects[2].selectedIndex === -1
      ) {
        clearAdvanced.style.display = "none";
      }
    });

    removePlaceholder(select, choice);

    window.advancedChoices.push(choice);
  });

  // Sub specialisms
  const specialismSelect = document.getElementById("specialism");
  const subSpecialismSelect = document.getElementById("sub-specialism");

  // Array of sub-specialisms
  let subSpecialismsList = [];

  // Sub-specialism choice component
  const subChoice = window.advancedChoices.find(
    (choice) => choice._baseId === "choices--sub-specialism"
  );

  // Sub-specialism select
  const specialismChoice = window.advancedChoices.find(
    (choice) => choice._baseId === "choices--specialism"
  );

  removePlaceholder(subSpecialismSelect, subChoice);

  // Add sub-specialisms
  if (specialismChoice.getValue().length) {
    const selectedSpecArr = specialismChoice.getValue();

    specialismData.forEach((spec) => {
      selectedSpecArr.forEach((sel) => {
        if (spec.id == sel.value) {
          subSpecialismsList.push(...spec.childs);
        }
      });
    });

    if (subSpecialismData) {
      const subSpecialismDataArr = subSpecialismData.split(",");

      const subSpecSelected = subSpecialismsList.filter((item) => {
        return subSpecialismDataArr.some((sel) => sel == item.id);
      });

      subChoice.setValue(mutateSpecialismObject(subSpecSelected));
      removePlaceholder(subSpecialismSelect, subChoice);
    }
  }

  // Event of adding sub-specialisms
  specialismSelect.addEventListener("addItem", (e) => {
    const selectValue = e.detail.value;

    const childrenOfSelected = specialismData
      .filter((el) => el.id == selectValue)
      .map((el) => el.childs)
      .flat(Infinity);

    subSpecialismsList.push(...childrenOfSelected);
  });

  // Event of removing sub-specialisms
  specialismSelect.addEventListener("removeItem", (e) => {
    const selectValue = e.detail.value;

    subSpecialismsList = subSpecialismsList.filter(
      (el) => el.specialism_id !== selectValue
    );
  });

  /* ---- Display sub-specialisms ---- */
  function mutateSpecialismObject(specialisms) {
    return specialisms.flat(Infinity).map((el) => {
      return {
        value: el.id,
        label: el.title,
      };
    });
  }

  if (subSpecialismsList.length) {
    subChoice.setChoices(
      mutateSpecialismObject(subSpecialismsList),
      "value",
      "label",
      true
    );
  }

  specialismSelect.addEventListener("change", () => {
    if (specialismSelect.selectedIndex === -1) {
      resetChoices(subChoice);
    }

    subChoice.setChoices(
      mutateSpecialismObject(subSpecialismsList),
      "value",
      "label",
      true
    );
  });

  /* ---- Reset forms ---- */
  function resetConsultantFilter() {
    const consultantHidden = document.getElementById("consultant");
    const linkWithoutConsultant = window.location.href.split("?consultant=")[0];

    if (linkWithoutConsultant && consultantHidden) {
      window.history.replaceState({}, "", linkWithoutConsultant);
      consultantHidden.value = "";
    }
  }

  // Reset main form
  clearMain.addEventListener("click", () => {
    mainFilters.forEach((input) => (input.value = ""));

    mainSelect.selectedIndex = -1;

    resetConsultantFilter();

    resetChoices(mainChoice);

    clearMain.style.display = "none";
  });

  // Reset advanced form
  clearAdvanced.addEventListener("click", () => {
    // Reset filters
    advancedSelects.forEach((select) => (select.selectedIndex = -1));

    resetConsultantFilter();

    // Reset choices
    window.advancedChoices.forEach((choice) => resetChoices(choice));
    subSpecialismsList = [];

    // Reset range slider
    const { range } = window.rangeSlider.noUiSlider.options;

    window.rangeSlider.noUiSlider.set([range.min, range.max], true, true);

    clearAdvanced.style.display = "none";
  });

  // Show clear buttons
  window.rangeSlider.noUiSlider.on(
    "change",
    () => (clearAdvanced.style.display = "block")
  );

  if (mainSelect.value) clearMain.style.display = "block";

  mainSelect.addEventListener("change", () => {
    clearMain.style.display = "block";

    if (
      !mainFilters[2].value &&
      !mainFilters[3].value &&
      mainSelect.selectedIndex === -1
    ) {
      clearMain.style.display = "none";
    }
  });

  mainFilters.forEach((filter, i) => {
    if (filter.value && filter.type !== "hidden") {
      clearMain.style.display = "block";
    }

    filter.addEventListener("keyup", (e) => {
      if (filter.classList.contains("input-second")) {
        if (filter.value) {
          clearMain.style.display = "block";
        }

        if (
          !mainFilters[2].value &&
          !mainFilters[3].value &&
          mainSelect.selectedIndex === -1
        ) {
          clearMain.style.display = "none";
        }
      }

      if (e.key === "Enter") {
        load(
          "jobs/search",
          "form:#form_filter",
          `brands=${getBrandIdsString()}`
        );
      }
    });
  });
}

// Footer padding
const infoSection = document.querySelector(".info-section");
if (!infoSection) {
  const footer = document.querySelector(".footer");
  if (footer) footer.style.paddingTop = 56 + "px";
}

// Charity section
const charitySection = document.querySelector(".about-section_charity");
if (charitySection) {
  const moreBtn = charitySection.querySelector(".more-btn");
  const charityContent = charitySection.querySelector(
    ".about-block__content-info"
  );

  function limitText(element) {
    const lineHeight = parseInt(window.getComputedStyle(element).lineHeight);
    const totalLines = Math.ceil(element.scrollHeight / lineHeight);

    element.classList.add("ellipsis");
    element.style.cssText = `-webkit-line-clamp: ${totalLines - 2}`;
  }

  const charityInner = charityContent.innerHTML;

  if (charityContent.innerHTML.length > 708) {
    // Show visible content
    const visibleContent = charityContent.innerHTML.substr(
      0,
      Math.ceil(charityContent.innerHTML.length / 1.56)
    );

    charityContent.innerHTML = visibleContent;

    // Limit last paragraph
    limitText(charityContent.children[charityContent.children.length - 1]);

    moreBtn.addEventListener("click", () => {
      moreBtn.classList.toggle("open");

      if (moreBtn.classList.contains("open")) {
        charityContent.innerHTML = charityInner;
        moreBtn.querySelector(".title").textContent = "Read Less";
      } else {
        charityContent.innerHTML = visibleContent;
        moreBtn.querySelector(".title").textContent = "Read More";

        // Limit last paragraph
        limitText(charityContent.children[charityContent.children.length - 1]);
      }
    });
  }
}

// ------- Sliders ------- //
const insightsSection = document.querySelector(".insights-section");
if (insightsSection) {
  const insightSlides = insightsSection.querySelectorAll(".swiper-slide");
  const insightsSwiper = new Swiper("#insights-slider", {
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 600,
    loop: insightSlides.length > 5,

    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    navigation: {
      nextEl: ".swiper-btn-next.insights-btn",
      prevEl: ".swiper-btn-prev.insights-btn",
    },
  });

  SafariSwiper(insightsSwiper);
}

const gallerySlider = document.querySelector(".gallery-slider");
if (gallerySlider) {
  const swiper = new Swiper(gallerySlider, {
    slidesPerView: "auto",
    spaceBetween: 20,
    loop: true,
    speed: 600,
    grabCursor: true,

    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  });

  SafariSwiper(swiper);
}

const vacanciesSlider = document.querySelector(".vacancies-slider");
if (vacanciesSlider) {
  const swiper = new Swiper(vacanciesSlider, {
    slidesPerView: 1,
    effect: "fade",

    fadeEffect: {
      crossFade: true,
    },

    pagination: {
      el: ".vacancies-pagination",
      type: "bullets",
      clickable: true,
    },

    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  });

  SafariSwiper(swiper);
}

const vacanciesCreative = document.querySelector(".vacancies-creative");
if (vacanciesCreative) {
  const swiper = new Swiper(vacanciesCreative, {
    slidesPerView: "auto",
    speed: 600,
    spaceBetween: 23,

    effect: "creative",
    creativeEffect: {
      limitProgress: 8,

      prev: {
        translate: [0, 0, -290],
        opacity: 0,
      },

      next: {
        translate: ["calc(100% + 23px)", 0, 0],
        opacity: 1,
      },
    },

    // Autoplay
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    // Navigation
    navigation: {
      nextEl: ".swiper-btn-next.vacancy-btn",
      prevEl: ".swiper-btn-prev.vacancy-btn",
    },

    // breakpoints: {},
  });

  SafariSwiper(swiper);
}

const testimonialsSlider = document.querySelector(".testimonials-swiper");
if (testimonialsSlider) {
  const swiper = new Swiper(testimonialsSlider, {
    speed: 500,
    spaceBetween: 30,
    pagination: {
      el: ".testimonials-swiper_wrapper .swiper-pagination",
      clickable: true,
    },
    // Autoplay
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    breakpoints: {
      // when window width is >= 1240px
      1241: {
        direction: "vertical",
      },
    },
  });

  SafariSwiper(swiper);
}

if(document.getElementsByTagName('a')[0]) {
  const links = document.getElementsByTagName('a');

  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    const linkText = link.innerText || link.textContent;

    if (/^@.*@$/.test(linkText)) {
      if (document.getElementsByTagName('a')[0]) {
        const links = document.getElementsByTagName('a');

        for (let i = 0; i < links.length; i++) {
          const link = links[i];
          const linkText = link.innerText || link.textContent;

          if (/^@.*@$/.test(linkText)) {

            const newDiv = document.createElement('div');
            newDiv.className = 'button-generated-wrap';

            const newLink = document.createElement('a');
            newLink.className = 'btn grey';
            newLink.href = link.href;

            if (link.hasAttribute("target")) {

              const existingTargetValue = link.getAttribute("target");

              newLink.setAttribute("target", `${existingTargetValue}`);
            }

            const circleSpan = document.createElement('span');
            circleSpan.className = 'circle';

            const textSpan = document.createElement('span');
            textSpan.textContent = linkText.slice(1, -1);

            newLink.appendChild(circleSpan);
            newLink.appendChild(textSpan);

            newDiv.appendChild(newLink);

            if (link.parentNode && link.parentNode.tagName.toLowerCase() === 'p') {
              link.parentElement.classList.add('button-generated-parent');
            }

            link.parentNode.replaceChild(newDiv, link);

            followOnHover(newLink, circleSpan);
          }
        }
      }
    }
  }
}

